import React from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

//Images
import { SingleProjectsIcons, TaigumPlaceParkImages } from '../imageURLs';

//Data
import keyProcesses from '../../data/taigum-park/key-processes.json';
import listItems from '../../data/taigum-park/premium-drainage.json';

import SEO from '../components/SEO';
import { withQuoteWizard } from '../components/withQuoteWizard';
import CenterContentMain from '../components/CenterContentMain';
import CenterContentNarrow from '../components/CenterContentNarrow';
import InnerImage from '../components/InnerImage/innerImage';
import SectionIcons from '../components/singleproject/sectionIcons';
import IntroBlock from '../components/singleproject/introBlock';
import ProcessText from '../components/singleproject/processText';
import SectionTitle from '../components/SectionTitle';
import TitleSmaller from '../components/TitleSmaller';
import FullWidthCarouselSlider from '../components/Carousel/FullWidthCarouselSlider';
import ListItemsBlock from '../components/ListItemsBlock';
import CtaBlock from '../components/CtaBlock';
import CTAButton from '../components/CTAButton/CTAButton';
import SimilarProjects from '../sections/SimilarProjects/SimilarProjects';
import { getElements } from '../helpers/functions';
import { similarProjects } from '../helpers/consts';

const sliderItems = [
  {
    imagePath: TaigumPlaceParkImages.slide1,
  },
  {
    imagePath: TaigumPlaceParkImages.slide2,
  },

];

const thumbSliderItems = [
  {
    imagePath: TaigumPlaceParkImages.thumb1,
  },
  {
    imagePath: TaigumPlaceParkImages.thumb2,
  },
  {
    imagePath: TaigumPlaceParkImages.thumb3,
  },  
];

const projectSliderItems = getElements(similarProjects, [1,2,4,5,6,7,8,9]);

const styles = theme =>
  createStyles({
    projectWrap: {
      marginBottom: 40,
    },

    introTop: {
      padding: '35px 0',
      [theme.breakpoints.up(767)]: {
        padding: '45px 0',
      },
      maxWidth: '850px',
      margin: '0 auto'
    },

    iconsWrapper: {
      justifyContent: 'center'
    },

    imageSection: {
      [theme.breakpoints.up(960)]: {
         padding: '20px 0px 80px 0',
     },
    },

    img: {
       width: '100%',
       height: '100%',
       objectFit: 'cover',
     },

     twoColumnsWrap: {
      alignItems: 'flex-start',
      paddingBottom: 50,
      flexWrap: 'wrap',
      paddingTop: 10,
      [theme.breakpoints.up(960)]: {
        flexDirection: 'row',
        paddingBottom: 62,
        paddingTop: 0,
      },
     },

    leftColumnWrap: {
      padding: '40px',
      [theme.breakpoints.up(960)]: {
        flex: '0 0 52%',
        maxWidth: '52%',
        paddingRight: 110,
      },
      [theme.breakpoints.up(1220)]: {
        padding: '40px 135px 40px 0'
      },
    },

    rightColumnWrap: {
      [theme.breakpoints.up(960)]: {
        flex: '0 0 48%',
        maxWidth: '48%',
      },
    },

    rightColumnSlider: {
      [theme.breakpoints.up(960)]: {
        marginTop: 70,
      },
    },

    sliderWrap: {
      marginTop: 20,
      [theme.breakpoints.up(767)]: {
        marginTop: 45
      },
    },

  });

const TaigumParkPage = ({classes,children}) => (

<React.Fragment>

  <SEO
    title="Taigum Park Place - Twin View Turf - Producer of Australia’s Best Lawns"
    keywords={[
      `Turf care guides`,
      `care guides`,
      `Turf`,
      `Lawns`,
      `Best Lawns`,
      `Australia’s Best Lawns`,
      `lawn producer`,
    ]}
  />

    {/* HERO SECTION START */}
    <InnerImage 
      image={{className: classes.img, src: TaigumPlaceParkImages.hero}}
    ></InnerImage>

    <CenterContentMain>
      <Grid
        container
        direction="column"
        className={classes.introTop}
      >

          <SectionTitle
            title="Taigum Place Park, Taigum"
            subTitle="Brisbane City Council"  
          > 
          </SectionTitle>

          <Grid
            container
            className={classes.iconsWrapper }
          >

          <SectionIcons
            iconImage= {SingleProjectsIcons.icon1}
            textAbove= "Service"
            textBelow= "Construction"
            ></SectionIcons>

          <SectionIcons
            iconImage= {SingleProjectsIcons.icon2}
            textAbove= "Type"
            textBelow= "AFL Field"
            ></SectionIcons>

          <SectionIcons
            iconImage= {SingleProjectsIcons.icon3}
            textAbove= "Turf Type"
            textBelow= "Nullarbor Couch"
            ></SectionIcons>

          <SectionIcons
            iconImage= {SingleProjectsIcons.icon4}
            textAbove= "Handover"
            textBelow= "Jan 18"
          ></SectionIcons>

          <SectionIcons
            iconImage= {SingleProjectsIcons.icon5}
            textAbove= "Value"
            textBelow="$425k"
          ></SectionIcons>

        </Grid>

      </Grid>
      </CenterContentMain>


      <CenterContentNarrow>
          <IntroBlock
            blockText="A total field upgrade complete with sub surface drainage system and new Nullarbor Couch playing surface."
            value1="20,000m2"
            text1=" of Nullabor Couch turf"
            value2="2,500m"
            text2=" of precision drainage"
            value3="3,100t"
            text3=" of premium sports blend top soil"
          >
          </IntroBlock>
      </CenterContentNarrow>

      {/* FEATURE SECTION START */}
      <CenterContentMain>
      <Grid
        container
        className={classes.imageSection}
      >
        <InnerImage
          image={{ className: classes.img, src: TaigumPlaceParkImages.feature }}
        ></InnerImage>
      </Grid>
      </CenterContentMain>

      {/* TWO COLUMNS SECTION START */}
      <CenterContentNarrow>
        <Grid
          container
          direction="column"
          className={classes.twoColumnsWrap}
        >
          {/* left column */}
          <Grid
            container
            direction="column"
            className={classes.leftColumnWrap}
          >
            <TitleSmaller
              title="Key Processes"
            ></TitleSmaller>

          {keyProcesses.map(process => (
            <ProcessText
              key={process.title}
              processTitle={process.title}
              processDesc={process.desc}

            />
            ))}

          </Grid>
          {/* right column */}
          <Grid
            container
            direction="column"
            className={classes.rightColumnWrap}
          >
            {/* right column-text */}

            <ListItemsBlock
              title="Premium Drainage"
              text={listItems}
              ctaText="Learn more"
              ctaHref="/drainage"
              inverted
            >
            </ListItemsBlock>

            {/* right column carousel */}

            <Grid
              container
              className={classes.rightColumnSlider}
            >
              <FullWidthCarouselSlider mediumImage items={thumbSliderItems} />
            </Grid>

          </Grid>
        </Grid>
      </CenterContentNarrow>

      {/* CAROUSEL SECTION START */}
        <SectionTitle
          title="The Transformation" 
          > 
        </SectionTitle>

      <CenterContentMain>
        <Grid
        container
        className={classes.sliderWrap}
        >
          <FullWidthCarouselSlider largeImage items={sliderItems} />
        </Grid>
      </CenterContentMain>

      <SimilarProjects cards={projectSliderItems} />
  
      {/* CTA SECTION START */}
      <CtaBlock title="Planning a field construction?">
        <CTAButton
          inverted
          ctaHref="/contact"
          ctaText="Call Us"
        />
      </CtaBlock> 
  {children}
</React.Fragment>

)

export default withQuoteWizard(withStyles(styles, { withTheme: true })(TaigumParkPage));